@use "sass:math";

// Colors
// ---------------------------------------------
$c-crimson: #a41034;
$c-crimson-light: #be0d38;
$c-red: #e80538;
$c-coral: #fb5f60;
$c-lime: #b4d56a;
$c-bright-blue: #9bcfff;
$c-charcoal: #222222;
$c-cream: #f6f4f2;
$c-black: #000000;
$c-white: #ffffff;
$c-tertiary-dark: #85609f;
$c-tertiary-light: #aac8eb;
$c-deep-teal: #00979e;
$c-teal1: #006085;
$c-purple: #964591;
$c-magenta: #78244c;
$c-green: #056934;

$c-yellow: #eccd00;
// Shades of gray
$c-black-1: rgba(0, 0, 0, 0.08);
$c-black-2: rgba(0, 0, 0, 0.21);
$c-black-3: rgba(0, 0, 0, 0.34);
$c-black-4: rgba(0, 0, 0, 0.45);
$c-black-5: rgba(0, 0, 0, 0.66);
$c-black-6: rgba(0, 0, 0, 0.85);
// Shades of white
$c-white-1: rgba(255, 255, 255, 0.15);
$c-white-2: rgba(255, 255, 255, 0.34);
$c-white-3: rgba(255, 255, 255, 0.44);
$c-white-4: rgba(255, 255, 255, 0.72);
$c-white-5: rgba(255, 255, 255, 0.82);
$c-white-6: rgba(255, 255, 255, 0.91);
// Theme specific variables
$theme-light-text-lighter: rgba(0, 0, 0, 0.55);
$theme-crimson-text-light: rgba(255, 255, 255, 0.85);
$theme-crimson-text-lighter: rgba(255, 255, 255, 0.81);
$theme-purple-text-light: rgba(255, 255, 255, 0.95);
$theme-purple-text-lighter: rgba(255, 255, 255, 0.92);
$theme-blue-text-lighter: rgba(0, 0, 0, 0.59);
$theme-red-text-light: rgba(255, 255, 255, 0.99);
$theme-red-text-lighter: rgba(255, 255, 255, 0.98);

// For convenience, store references to CSS variables in SASS variables
// See _themes.scss for CSS variables
$c-theme-solid: var(--c-solid);
$c-theme-solid-inverse: var(--c-solid-inverse);
$c-bg: var(--c-bg);
$c-bg-transparent: var(--c-bg-transparent);
$c-bg-inverse: var(--c-bg-inverse);
$c-bg-complementary: var(--c-bg-complementary);
$c-spot: var(--c-spot);
$c-accent-dark: var(--c-accent-dark);
$c-accent-light: var(--c-accent-light);
$c-text: var(--c-text);
$c-text-link: var(--c-text-link);
$c-text-light: var(--c-text-light);
$c-text-lighter: var(--c-text-lighter);
$c-border-dark: var(--c-border-dark);
$c-border: var(--c-border);
$c-border-light: var(--c-border-light);
$c-button-bg: var(--c-button-bg);
$c-button-bg-subtle: var(--c-button-bg-subtle);
$c-button-bg-strong: var(--c-button-bg-strong);
$c-submenu-bg: var(--c-submenu-bg);
$c-highlight: var(--c-highlight);
$c-outline: var(--c-outline);

$kaltura-gradient: radial-gradient(
  at bottom left,
  rgba(0, 0, 0, 0.8),
  transparent 50%
);

// Typography
// ---------------------------------------------
// Font Family
$ff-graphik: Graphik;
$ff-tiempos: Tiempos;

$ff-sans: $ff-graphik, sans-serif, system-ui;
$ff-serif: $ff-tiempos, serif, system-ui;

// Font Weights
$fw-light: 300;
$fw-reg: 400;
$fw-med: 500;
$fw-semibold: 600;
$fw-bold: 700;

// Font Sizes - Scale = Augmented Fourth (https: //type-scale.com/)
$fs-xxs: 11px;
$fs-xs: 14px;
$fs-sm: 16px;
$fs-md: 20px;
$fs-lg: 24px;
$fs-xl: 31.99px;
$fs-xxl: 45px;
$fs-xxxl: 63.96px;
$fs-xxxxl: 90.4px;
$fs-giant: 115px;
$fs-ginormous: 180.83px;
$fs-gargantuan: 230.69px;

// Font size applications
$fs-base: $fs-sm;

// Article Headings Font Sizes - Scale = Minor third (https: //type-scale.com/)
$fs-base-article: 16px;
$fs-sm-article: 19.2px;
$fs-md-article: 23.04px;
$fs-lg-article: 27.65px;
$fs-xl-article: 33.18px;
$fs-xxl-article: 39.81px;
$fs-xxxl-article: 47.78px;
$fs-xxxxl-article: 57.33px;

// Line Height
$lh-base: 1.4;
$lh-tiny: 1;
$lh-xs: 1.05;
$lh-sm: 1.2;
$lh-md: 1.35;
$lh-lg: 1.55;
$lh-h1: $lh-tiny;
$lh-h2: $lh-tiny;
$lh-h3: $lh-xs;
$lh-h4: $lh-sm;
$lh-h5: $lh-sm;
$lh-h6: $lh-sm;

// Letterspacing
$ls-serif-small: 0em;
$ls-serif-large: -0.03em;
$ls-sans-small: -0.025em;
$ls-sans-large: -0.045em;

// Grid
// ---------------------------------------------
$col-16: 100%;
$col-1: math.div($col-16, 16);
$col-2: $col-1 * 2;
$col-3: $col-1 * 3;
$col-4: $col-1 * 4;
$col-5: $col-1 * 5;
$col-6: $col-1 * 6;
$col-7: $col-1 * 7;
$col-8: $col-1 * 8;
$col-9: $col-1 * 9;
$col-10: $col-1 * 10;
$col-11: $col-1 * 11;
$col-12: $col-1 * 12;
$col-13: $col-1 * 13;
$col-14: $col-1 * 14;
$col-15: $col-1 * 15;
$col-one-third: math.div($col-16, 3);
$col-one-sixth: math.div($col-16, 6);

// Breakpoints
// ---------------------------------------------
$bp-mobile: 300px;
$bp-tablet: 600px;
$bp-tablet-xl: 800px;
$bp-desktop: 1024px;
$bp-desktop-lg: 1200px;
$bp-desktop-xl: 1340px;
$bp-full-grid: $bp-tablet;

// Spacing
// ---------------------------------------------
$spacing-xxxs: 2px;
$spacing-xxs: 5px;
$spacing-xs: 8px;
$spacing-sm: 12px;
$spacing-md: 18px;
$spacing-lg: 24px;
$spacing-xl: 36px;
$spacing-xxl: 60px;
$spacing-xxxl: 120px;
$spacing-xxxxl: 160px;

$menu-top-spacing: $spacing-xxxxl;

// Margins
$font-margin-base: 1em 0 0;
$font-margin-h6: 1.5em 0 0.5em;
$font-margin-h5: 1.5em 0 0.5em;
$font-margin-h4: 1.5em 0 0.25em;
$font-margin-h3: 1.5em 0 0.25em;
$font-margin-h2: 1.25em 0 0.25em;
$font-margin-h1: 1.25em 0 0.25em;

// Lists
$list-item-margin: $spacing-xxs;
$list-margin: $spacing-md 0 $spacing-md;

// Grid Spacing
$grid-gutter-mobile: 16px;
$grid-padding-mobile: $grid-gutter-mobile * 0.5;
$grid-gutter: 2vw;
$grid-padding: $grid-gutter * 0.5;

// Transitions
// ---------------------------------------------
$t-time: 0.2s;
$t-time-xs: 0.1s;
$t-time-sm: 0.4s;
$t-time-md: 0.6s;
$t-ease-out: cubic-bezier(0, 0.55, 0.45, 1);
$t-ease-in-out: cubic-bezier(0.65, 0, 0.35, 1);
$transition: $t-time $t-ease-in-out;
$transition-sm: $t-time-sm $t-ease-in-out;
$transition-md: $t-time-md $t-ease-in-out;

// Outlines
// ---------------------------------------------
$outline: 4px solid $c-outline;

// Borders
// ---------------------------------------------
$border: 1px solid $c-border;
$border-solid: 1px solid $c-border-dark;
$border-light: 1px solid $c-border-light;
$border-dark: 1px solid $c-border-dark;
$print-border: 1mm solid black;

// Border Radii
// ---------------------------------------------
$border-radius: 4px;

// Sizes
// ---------------------------------------------
$dot-size: clamp($fs-md, 3vw, $fs-xl);
$icon-size: 20px;
$icon-size-sm: 13px;
$icon-size-xs: 11px;
$site-max-width: 1800px;
$display-text-max-width: 820px;
$caret-size: 7px;
$caret-size-lg: 9px;
$caret-size-xl: 11px;
$expand-collapse-arrow-size: 10px;
$social-icon-size: 1.75em;
$header-shift: 45px;
$card-min-height: 450px;
$card-min-height-sm: 380px;
$quote-carousel-card-height: 400px;
$book-size: 250px;
$kaltura-play-btn-size: 65px;
$kaltura-play-btn-size-sm: 40px;
$kaltura-play-icon-size: 10px;
$carousel-button-size: 50px;
$nav-bg-height: 100000px;

// z-indices
// ---------------------------------------------
$z-index-0: 0;
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 40;
$z-index-5: 50;
$z-index-6: 60;
$z-index-7: 70;
$z-index-8: 80;
$z-index-9: 90;

// box-shadows
// ---------------------------------------------
$box-shadow: 0 10px 45px transparentize($c-black, 0.92);

// Selectors
// ---------------------------------------------
// Tease Row
$hbs-tease-row-container: ".hbs-tease-row-container.hbs-tease-row-container";
$hbs-tease-row: ".hbs-tease-row.hbs-tease-row";
$hbs-tease-row--2-up: "#{$hbs-tease-row}.hbs-tease-row--2-up";
$hbs-tease-row--3-up: "#{$hbs-tease-row}.hbs-tease-row--3-up";
$hbs-tease-row--4-up: "#{$hbs-tease-row}.hbs-tease-row--4-up";

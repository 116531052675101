@mixin grid-parent($prop: padding, $offset: false, $flex: true) {
  @if $flex {
    display: flex;
    flex-flow: row wrap;
  }

  @if $offset {
    @include margin(x #{-$grid-padding-mobile});
    width: calc(100% + 2 * $grid-padding-mobile);
  } @else {
    #{$prop}-left: $grid-padding-mobile;
    #{$prop}-right: $grid-padding-mobile;
  }

  @include mq($bp-full-grid) {
    @if $offset {
      @include margin(x #{-$grid-padding});
      width: calc(100% + 2 * $grid-padding);
    } @else {
      #{$prop}-left: $grid-padding;
      #{$prop}-right: $grid-padding;
    }
  }
}

@mixin grid-child($prop: padding) {
  #{$prop}-left: $grid-padding-mobile;
  #{$prop}-right: $grid-padding-mobile;
  width: 100%;

  @include mq($bp-full-grid) {
    #{$prop}-left: $grid-padding;
    #{$prop}-right: $grid-padding;
  }
}

@mixin ungrid {
  @include padding(x 0);
  @include margin(x #{-$grid-padding-mobile});
  width: calc(100% + 2 * $grid-padding-mobile);

  @include mq($bp-tablet) {
    @include margin(x #{-$grid-padding});
    width: calc(100% + 2 * $grid-padding);
  }
}

// Write less verbose media queries.
// $bp-1 - breakpoint value, min-width if declaring range
// $bp-2 [null] - [Optional] breakpoint value, max-width if declaring range
//
// @example scss - SCSS
// .item-1 {
//    @include mq(600px, 768px) {
//     display: block;
//   }
// }
//
// .item-2 {
//   @include mq(768px) {
//     display: block;
//   }
// }

@mixin mq($bp-1, $bp-2: null) {
  @if $bp-1 == "max" {
    @media (max-width: ($bp-2 - 1)) {
      @content;
    }
  } @else if $bp-2 == "max" {
    @media (max-width: ($bp-1 - 1)) {
      @content;
    }
  } @else if $bp-2 == null {
    @media (min-width: $bp-1) {
      @content;
    }
  } @else {
    @media (min-width: $bp-1) and (max-width: ($bp-2 - 1)) {
      @content;
    }
  }
}

@mixin mq-reduced-motion {
  @media (prefers-reduced-motion: no-preference) {
    @content;
  }
}

@use "sass:math";
@import "../../../../styles/base/abstracts";

.hbs-landing-page-body {
  @include grid-parent;
  @include site-max-width;
  align-items: baseline;
}

.hbs-landing-page-body__main {
  @include grid-child;

  @include mq($bp-desktop) {
    margin-left: $col-4;
    margin-right: 0;
    width: $col-9;
  }

  @include mq($bp-desktop-xl) {
    width: $col-8;
  }
}

.hbs-landing-page-body__content {
  display: flow-root;

  // Align full width
  > .hbs-global-align-full {
    @include mq($bp-desktop) {
      margin-left: calc(#{math.div($col-8, $col-9) * 50%} - 50vw - 2px);
      margin-right: calc(#{math.div($col-3, $col-9) * -100%} - 2.6666vw);
    }

    @include mq($bp-desktop-xl) {
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }
}
